const state = {
    publishedFromDate: "",
    publishedToDate: "",
    metadataAdToDate: "",
    metadataProgramToDate: "",
    excelExportFilePreName: "",
    applicationHeader: "",
    clientVersion: "",
    apiVersion: "",
    fullAccess: false,
    ssasInfo: {},
}
const getters = {
    getPublishedFromDate: state => state.publishedFromDate,
    getPublishedToDate: state => state.publishedToDate,
    getMetadataAdToDate: state => state.metadataAdToDate,
    getMetadataProgramToDate: state => state.metadataProgramToDate,
    getExcelExportFilePreName: state => state.excelExportFilePreName,
    getApplicationHeader: state => state.applicationHeader,
    getClientVersion: state => state.clientVersion,
    getApiVersion: state => state.apiVersion,
    getSsasInfo: state => state.ssasInfo,
}
const mutations = {
    setPublishedFromDate: (state, payload) => (state.publishedFromDate = payload),
    setPublishedToDate: (state, payload) => (state.publishedToDate = payload),
    setMetadataAdToDate: (state, payload) => (state.metadataAdToDate = payload),
    setMetadataProgramToDate: (state, payload) => (state.metadataProgramToDate = payload),
    setExcelExportFilePreName: (state, payload) => (state.excelExportFilePreName = payload),
    setApplicationHeader: (state, payload) => (state.applicationHeader = payload),
    setClientVersion: (state, payload) => (state.clientVersion = payload),
    setApiVersion: (state, payload) => (state.apiVersion = payload),
    setFullAccess: (state, payload) => (state.fullAccess = payload),
    setSsasInfo: (state, payload) => (state.ssasInfo = payload),
}

export default {
    state,
    mutations,
    getters,
}
