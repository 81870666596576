<template>
    <div class="subnavbackground">
        <div>
            <button id="tooltip-target-ssasinfo" class="this-tool-icon-container clientinfotv">
                <img class="this-tool-icon d-block" src="../../../assets/images/tv-blue.svg" alt="" />
            </button>
            <b-tooltip v-if="SSASMessage != null || config_CurrentClientVersion != null" target="tooltip-target-ssasinfo" triggers="hover" custom-class="infoTooltip">
                <p>Klient version: {{ config_CurrentClientVersion }}</p>
                <p>API version: {{ SSASMessage.version }}</p>
                <p>Modell: {{ SSASMessage.linkedSSAS }}</p>
                <p>Senaste SSAS processning: {{ SSASMessage.lastProcessed }}</p>
                <p>Första publiceringsdag content: {{ SSASMessage.contentPublishedFromDate }}</p>
                <p>Sista publiceringsdag content: {{ SSASMessage.contentPublishedToDate }}</p>
                <p>Första publiceringsdag ad: {{ SSASMessage.adPublishedFromDate }}</p>
                <p>Sista publiceringsdag ad: {{ SSASMessage.adPublishedToDate }}</p>
            </b-tooltip>
            <b-button v-if="config_NewDataUrl" v-b-tooltip.hover.right="'Data efter 2023-12-10'" @click="navigateToNewData" class="ml-1" style="font-size: 0.8rem" size="sm" variant="primary">
                Se nyare data
            </b-button>
        </div>
        <div class="centered">
            <router-link to="/programs">Program</router-link>
            <router-link to="/campaigns" v-if="config_ShowCampaignTab || config_ExternalCampaignTabLink">Reklam</router-link>
            <router-link to="/channels">Kanaler</router-link>
        </div>
        <div v-if="anyFormFieldsLoaded" class="right">
            <b-button id="infoButton" v-b-toggle.sidebar-1 class="btn-xs mx-2" variant="primary">
                <b-icon icon="info-circle" />
            </b-button>
            <b-tooltip target="infoButton" triggers="hover" placement="top">
                Se informationslogg
            </b-tooltip>

            <b-button id="bookButton" class="btn-xs" variant="info" @click="openInfoLink">
                <b-icon icon="book" />
                <span />
            </b-button>
            <b-tooltip target="bookButton" triggers="hover" placement="top">
                Läs användarmanual
            </b-tooltip>
        </div>
    </div>
</template>

<script>
import globalConfig from "../../../mixins/globalConfig"
export default {
    mixins: [globalConfig],
    name: "SubNav",
    computed: {
        SSASMessage() {
            return this.$store.getters.getSsasInfo
        },
        anyFormFieldsLoaded() {
            let anyFormFieldsLoaded =
                this.$store.getters.getProgramsInitStatus === "LOADED" || this.$store.getters.getAdsInitStatus === "LOADED" || this.$store.getters.getChannelsInitStatus === "LOADED"
            return anyFormFieldsLoaded
        },
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },
    methods: {
        openInfoLink() {
            window.open("https://mms.se/wp-content/uploads/manual-totalanalys.pdf", "_blank")
        },
        navigateToNewData() {
            window.location.replace(this.config_NewDataUrl)
        },
    },
}
</script>

<style scoped>
a {
    text-transform: uppercase;
    margin: 5px 20px;
    position: relative;
    display: inline-block;
    color: #337ab7;
}
.router-link-exact-active {
    font-weight: bold;
}
.subnavbackground {
    background-color: white;
    border-bottom: #aaa 1px dotted;
    height: 39px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subnavbackground div {
    height: inherit;
    display: flex;
    align-items: center;
}
.centered {
    position: absolute;
    left: 50%;
    right: 50%;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
.right {
    display: flex;
    align-items: center;
}
.clientinfotv {
    background: none;
    border: none;
    padding: 0;
    cursor: default;
}
.btn-xs {
    padding: 0.25rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem;
}
</style>
